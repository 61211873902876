import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  width: "1.2em",
  height: "1.2em",
  preserveAspectRatio: "xMidYMid meet",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { fill: "none" }, [
      _createElementVNode("path", {
        d: "M10.496 16.803l6.245-6.304a4.408 4.408 0 0 0-.017-6.187a4.306 4.306 0 0 0-6.135-.015l-.596.603l-.605-.61a4.301 4.301 0 0 0-6.127-.016c-1.688 1.705-1.68 4.476.016 6.189l6.277 6.34c.26.263.682.263.942 0zM11.3 5a3.306 3.306 0 0 1 4.713.016a3.408 3.408 0 0 1 .016 4.78v.002l-6.004 6.06l-6.038-6.099c-1.313-1.326-1.314-3.47-.015-4.782a3.302 3.302 0 0 1 4.706.016l.96.97a.5.5 0 0 0 .711 0L11.3 5z",
        fill: "currentColor"
      })
    ], -1)
  ])))
}

export default { name: 'fluent-heart-20-regular', render }
/* vite-plugin-components disabled */