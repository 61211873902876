<template>
  <div
      class="header mobile-header left-0 top-0 px-4 fixed z-50 w-full h-16 transition duration-700 drop-shadow-lg visible lg:invisible">
    <div class="relative z-10 flex items-center justify-between w-full h-full py-5">
      <a href="/" class="flex-none">
        <img src="/img/logo-white.png" alt="logo" class="w-[130px] p-1"/>
      </a>

      <div class="mob-header-actions">
        <router-link to="/search" class="search-btn-mob">
          <Button icon="pi pi-search" class="p-button-rounded p-button-secondary p-button-text"/>
        </router-link>
        <auth/>
      </div>
    </div>
  </div>

</template>
<script>
import {ref} from "vue";
import useHeaderRoute from "@hooks/useHeaderRoute";
import Auth from "../Auth.vue";
import {mapActions, mapGetters} from 'vuex';

export default {
  setup() {
    const {currentRoute, routes} = useHeaderRoute();

    const isTop = ref(true);

    return {
      currentRoute,
      routes,
      isTop,
    };
  },

  components: {
    Auth,
  },

  data() {
    return {
      visibleUserMenu: false,
    };
  },
  computed: {
    ...mapGetters(['showMenu', 'mirror'])
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    ...mapActions(['toggleSidebar', 'logout']),
    handleScroll() {
      this.isTop = window.scrollY === 0;
    },
  },
};
</script>

<style>
.header__overlay {
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(10%, rgba(12, 18, 26, 0.7)),
      color-stop(10%, rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
      top,
      rgba(12, 18, 26, 0.7) 10%,
      rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
      top,
      rgba(12, 18, 26, 0.7) 10%,
      rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
      to bottom,
      rgba(12, 18, 26, 0.7) 10%,
      rgba(0, 0, 0, 0)
  );
}
</style>