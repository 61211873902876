import {createRouter, createWebHashHistory} from "vue-router";

const Home = () => import("@pages/Home.vue");

// const Media = () => import("@pages/media/Media.vue");
const EpisodeLatest = () => import("@pages/media/EpisodeLatest.vue");

const History = () => import("@pages/media/History.vue");
const EpisodeLiked = () => import("@pages/media/EpisodeLiked.vue");
import DetailsModal from "./components/DetailsModal.vue";
import TheatreWatch from "./pages/theatre/TheatreWatch";
import Faq from "./pages/Faq.vue";


const Watch = () => import("@pages/WatchVideoJS.vue");

//Movie
const MovieStream = () => import("@pages/movie/MovieStream.vue");
const Search = () => import("@pages/Search.vue");

const Profile = () => import("@pages/profile/index.vue");
const ProfileForm = () => import("@pages/profile/profile-form.vue");
const ChangePassword = () => import("@pages/profile/change-password.vue");
const MyFund = () => import("@pages/profile/my-fund.vue");
const ExtendRights = () => import("@pages/profile/extend-rights.vue");
const ViewedHistory = () => import("@pages/profile/viewed-history.vue");

const Payment = () => import("@pages/payment/index.vue");
const Qpay = () => import("@pages/payment/qpay.vue");
const Transfer = () => import("@pages/payment/transfer.vue");
const  ChooseAmount = () => import("@pages/payment/choose_amount.vue");
const  ChooseBankAmount = () => import("@pages/payment/choose_bank_payment.vue");
const Card = () => import("@pages/payment/card.vue");
const Content = () => import("@pages/Content.vue");

const Actor = () => import("@pages/Actor");
const Stream = () => import("@pages/media/Stream.vue");
const Policy = () => import("@pages/Policy");

//Theatre
const Theatre = () => import("@pages/theatre/Theatre.vue");
const MediaContent = () => import("@pages/theatre/MediaContent.vue");
const MediaGrid = () => import("@pages/theatre/SeerooMedia.vue");
const TheatreMedia = () => import("@pages/theatre/TheatreMedia.vue");
const TheatreDetail = () => import("@pages/theatre/TheatreDetail.vue");
const Podcast = () => import("@pages/podcast/podcast.vue");
const PodcastDetail = () => import("@pages/podcast/podcast-detail.vue");
const NewsLatter = () => import('@pages/news-latter/index.vue');
const NewsLatterDetail = () => import('@pages/news-latter/detail/index.vue')

const Contact = () => import("@pages/Contact.vue");

const routes = [
    {
        path: "/",
        component: Home,
    },
    {
        path: "/live",
        component: Home,
    },
    {
        path: "/podcast",
        component: Podcast,
    },
    {
        path: "/podcast/detail/:id",
        component: PodcastDetail,
    },
    {
        path: "/news-latter",
        component: NewsLatter,
    },
    {
        path: "/news-latter/detail/:id",
        component: NewsLatterDetail,
    },
    {
        path: "/media",
        component: MediaContent
    },
    {
        path: "/series",
        component: Theatre,
        children: [
            {
                path: '',
                redirect: () => {
                    return '/series/all/all/all/all?t=Бүх%20ангилал';
                }
            },
            {
                path: ":cat/:season/:status/:sort",
                component: MediaGrid,
            },
        ]
    },
    {
        path: "/series/detail/:id",
        component: DetailsModal
    },
    {
        path: "/episode/new",
        component: EpisodeLatest
    },
    {
        path: "/episode/liked",
        component: EpisodeLiked
    },

    {
        path: "/movie/stream/:id",
        component: MovieStream,
    },

    {
        path: "/payment",
        component: Payment,
    },
    {
        path: "/payment/qpay",
        component: Qpay,
    },
    {
        path: "/payment/transfer",
        component: Transfer,
    },
    {
        path: "/choose/amount",
        component: ChooseAmount,
    },
    {
        path: "/choose/bank/payment",
        component: ChooseBankAmount,
    },
    {
        path: "/payment/card",
        component: Card,
    },
    {
        path: "/search",
        component: Search,
    },
    {
        path: "/content/:id",
        component: Content,
    },
    {
        path: "/contact",
        component: Contact,
    },
    {
        path: "/watch/:media/",
        component: Stream,
        children: [
            {
                path: ":episode",
                component: Watch,
            },
        ]
    },

    {
        path: "/profile",
        component: Profile,
        children: [
            {
                path: "/profile/",
                component: ProfileForm,
            },
            {
                path: "/profile/change-password",
                component: ChangePassword,
            },
            {
                path: "/profile/my-fund",
                component: MyFund,
            },
            {
                path: "/profile/extend-rights",
                component: ExtendRights,
            },
            {
                path: "/profile/viewed-history",
                component: ViewedHistory,
            },
        ]
    },

    {
        path: "/actor/:id",
        component: Actor,
    },
    {
        path: "/policy",
        component: Policy,
        props: {
            isHeader: false,
            isSidebar: false,
            isFooter: false
        },
    },

    {
        path: "/theatre",
        component: Theatre,
        name: "Theatre",
        children: [
            {
                path: '/theatre',
                redirect: '/theatre/all/all'
            },
            {
                path: ":cat/:sort",
                component: TheatreMedia,
            },
        ]
    },
    {
        path: "/theatre/detail/:id",
        component: TheatreDetail,
    },

    {
        path: "/theatre/watch/:id",
        component: TheatreWatch,
    },
    {
        path: "/faq",
        component: Faq,
    },

    {
        path: "/history",
        component: History,
    },

    {path: "/:pathMatch(.*)*", redirect: "/"},
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    linkActiveClass: 'active',
    scrollBehavior(to, __, savedPosition) {
        if (to.fullPath == '/live') {
            setTimeout(() => {
                const el = document.getElementById('live');
                if (el) {
                    // Use el.scrollIntoView() to instantly scroll to the element
                    // el.scrollIntoView({behavior: 'smooth'});

                    let positionY = 0;
                    positionY += el.offsetTop + el.offsetHeight;
                    window.scrollTo({top: positionY, behavior: 'smooth'});
                }
            }, 500)
        }

        if (to.query.media == undefined) {
            if (to.query.scrollTop) {
                return {left: 0, top: to.query.scrollTop};
            }

            if (savedPosition) {
                return savedPosition;
            }

            return {left: 0, top: 0};
        }
    },
});

export default router;
