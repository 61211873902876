import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  width: "1.2em",
  height: "1.2em",
  preserveAspectRatio: "xMidYMid meet",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { fill: "none" }, [
      _createElementVNode("path", {
        d: "M20 20L4 4m16 0L4 20",
        stroke: "currentColor",
        "stroke-width": "2",
        "stroke-linecap": "round"
      })
    ], -1)
  ])))
}

export default { name: 'akar-icons-cross', render }
/* vite-plugin-components disabled */