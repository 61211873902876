{{ set enableSubtitle = subtitle && subtitle.type === 'webvtt' }}
<video
    class="dplayer-video {{ if current }}dplayer-video-current{{ /if }}"
    webkit-playsinline
    {{ if airplay }} x-webkit-airplay="allow" {{ /if }}
    playsinline
    {{ if pic }}poster="{{ pic }}"{{ /if }}
    {{ if preload }}preload="{{ preload }}"{{ /if }}
    {{ if url }}src="{{ url }}"{{ /if }}
    cross-origin
    >

    {{ if enableSubtitle }}
    <track id="subTrack" default kind="subtitles" type="text/vtt" data-raw="{{ subtitle.isRaw }}" src="{{ subtitle.path }}" />
    {{ /if }}
</video>

<audio class="max-audio">
    <source src="{{ audio }}" />
</audio>
