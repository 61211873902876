import api from "../_boot/baseApi";

export const getContentData = ({commit}, payload) => {
    payload.vm.isLoading = true;
    api.get(`/api/m/content/${payload.id}`).then(({data}) => {
        commit('setContent', data.data);
        payload.vm.isLoading = false;
    })
}

export const getPaymentData = ({commit}, payload) => {
    payload.vm.isLoadingInfo = true;
    api.get(`/api/bank/account`).then(({data}) => {
        console.log("dataBankAccount--------->",data);
        commit('setPaymentContent', data.data);
        payload.vm.isLoadingInfo = false;
    })
}